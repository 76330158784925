import '../styles/Project2.css';

import demoGif from '../media/portfolioItems/text_editor_demo_gif.gif';


function Project2(){
    return (
        <div className='Project2-container'>
                    <div className='Title'>Focus Mode Text Editor</div>
                    <div className="Proj2-sub-box">
                        <div className='Demo-gif-box'>
                            <img alt="Text Editor Demo Gif" src={demoGif} className='Demo-gif'/>
                        </div>
                        <div className="Blurb-box-2">
                            As a musician and songwriter, I am frequently annoyed by text editors distracting me. 
                            Additionally, I was tired of having to fiddle with formatting simply to make it bigger so I can see it from a distance (for recording). 
                            So I built my own simple standalone text editor in Electron!
                        </div>
                    </div>
        </div>
    );
}

export default Project2;