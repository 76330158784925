import '../styles/AboutCol.css';
import CSSLogo from '../media/badges/CSS3_logo_and_wordmark.svg.png';
import ElectronLogo from '../media/badges/Electron_Software_Framework_Logo.svg.png';
import GitLogo from '../media/badges/Git-Logo-White.png';
import HTMLLogo from '../media/badges/HTML5_Badge_512.png';

import FirebaseLogo from '../media/badges/firebase.png';
import CppLogo from '../media/badges/ISO_C++_Logo.png';
import JavaLogo from '../media/badges/java.png';

import JavaScriptLogo from '../media/badges/JavaScript-logo.png';
import CSharpLogo from '../media/badges/Logo_C_sharp.png';
import MongoLogo from '../media/badges/mongodb_original_logo_icon_146424.png';
import PythonLogo from '../media/badges/Python-logo-notext.png';

import ReactLogo from '../media/badges/React-icon.png';
import ReactNativeLogo from '../media/badges/react-native-logo-884x1024.webp';
import SQLLogo from '../media/badges/Sql_data_base_with_logo.png';

import ProfilePicture from '../media/Me.jpeg';


function AboutCol(){
    return (
        <div className='Column-container'>
            {/* Name and title, top level */}
            <div className='Name-row'>
                <div className='Name-text'>
                    ELYSE SHACKLETON
                </div>
                <div className='Title-text'>
                    SOFTWARE ENGINEER
                </div>
            </div>

            {/* Row containing image and badge container*/}
            <div className='Badge-row'>
                {/* Profile image */}
                <img src ={ProfilePicture} className='Profile-picture'/>
                
                {/* Box of badges */}
                <div className='Badge-group-holder'>
                
                    {/* Full-Stack declaration */}
                    <div className='Full-stack'>
                        FULL-STACK
                    </div>

                    {/* A row of badges */}
                    <div className='Badge-group'>
                        <img alt="React Logo" src={ReactLogo} className='Badge'/>
                        <img alt="CSS Logo" src={CSSLogo} className='Badge'/>
                        <img alt="HTML Logo" src={HTMLLogo} className='Badge'/>
                        <img alt="Firebase Logo" src={FirebaseLogo} className='Badge'/>
                    </div>

                    {/* A row of badges */}
                    <div className='Badge-group'>
                        <img alt="Git Logo" src={GitLogo} className='Badge'/>
                        <img alt="C++ Logo" src={CppLogo} className='Badge'/>
                        <img alt="Java Logo" src={JavaLogo} className='Badge'/>
                    </div>

                    {/* A row of badges */}
                    <div className='Badge-group'>
                        <img alt="Electron Logo" src={ElectronLogo} className='Badge'/>
                        <img alt="JavaScript Logo" src={JavaScriptLogo} className='Badge'/>
                        <img alt="C# Logo" src={CSharpLogo} className='Badge'/>
                        <img alt="Python Logo" src={PythonLogo} className='Badge'/>
                    </div>

                      {/* A row of badges */}
                      <div className='Badge-group'>
                        <img alt="MongoDb Logo" src={MongoLogo} className='Badge'/>
                        <img alt="ReactNative Logo" src={ReactNativeLogo} className='Badge'/>
                        <img alt="SQL Logo" src={SQLLogo} className='Badge'/>
                    </div>
                </div>
            </div>



            {/* Row containing bio */}
            <div className='Bio-row'>
                I'm an industrious, self-motivated, and friendly full-stack software engineer. 
                My accomplishments include being a <a target="_blank" href="https://ieeexplore.ieee.org/document/9092169">published researcher</a> in the International Conference on Information and Computer Technologies (ICICT 2020) 
                by the Institute of Electrical and Electronics Engineers (IEEE).
            </div>
        </div>
    );
}

export default AboutCol;