import '../styles/ProjectsCol.css';

import WWScreen1 from '../media/portfolioItems/ww1.png';
import WWScreen2 from '../media/portfolioItems/ww2.png';
import WWScreen3 from '../media/portfolioItems/ww3.png';
import WWScreen4 from '../media/portfolioItems/ww4.png';


function ProjectsCol(){
    return (
        <div className='Projects-container'>
                    <div>
                        <div className='Title'>When&Where</div>
                        <div className='WW-screen-group'>
                            <img alt="When&Where Screen 2" src={WWScreen2} className='ScreenWW'/>
                            <img alt="When&Where Screen 3" src={WWScreen3} className='ScreenWW'/>
                            <img alt="When&Where Screen 4" src={WWScreen4} className='ScreenWW'/>
                        </div>
                        <div>
                            Event-planning mobile app developed on a wonderful 6-person team using React Native and Firebase. 
                            On my 21st birthday, I drove around to everyone's apartments to fix their database calls. <a target="_blank" href="https://github.com/joshuawoodapu/when-and-where">[Github]</a> 
                        </div>
                    </div>

        </div>
    );
}

export default ProjectsCol;