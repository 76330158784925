import './styles/App.css';
import AboutCol from './components/AboutCol.js';
import ProjectsCol from './components/ProjectsCol.js';
import Project2 from './components/Project2.js';
import Project3 from './components/Project3.js';


function App() {
  return (
    <div className="App">
      {/* Top half */}
      <div className="Top-container">
            <div className={"Page-container"}>

                {/* About column */}
                <AboutCol />
                
                {/* Projects column */}
                <ProjectsCol />
            </div>
      </div>

      {/* Top half */}
      <div className="Bottom-container">
            <div className={"Page-container"}>

                
                {/* Projects column */}
                <Project2 />


                {/* About column */}
                <Project3 />
            </div>
      </div>
    </div>
  );
}

export default App;
