import '../styles/Project3.css';
import RecipeGif1 from '../media/portfolioItems/recipescale1.gif';
import RecipeGif2 from '../media/portfolioItems/recipescale2.gif';
import RecipeGif4 from '../media/portfolioItems/recipescale4.gif';



import demoPng from '../media/portfolioItems/keyswitching.png';


function Project2(){
    return (
        <div className='Project3-container'>
                    <div className='Title'>RecipeScale</div>
                    <div className='Recipe-scale-box'> 
                        <div className='WW-screen-group'>
                            <img alt="RecipeScale screen 1" src={RecipeGif1} className='Screen'/>
                            <img alt="RecipeScale screen 2" src={RecipeGif2} className='Screen'/>
                            <img alt="RecipeScale screen 3" src={RecipeGif4} className='Screen'/>
                        </div>
                        <div className="Blurb-box">
                            A responsive recipe-scaling mobile app built with React Native and Firebase. Features include authentication, 
                            recipe saving, and the ability to input a recipe and then scale it off of a change to any ingredient.                             <a target="_blank" href="https://github.com/elyshack/RecipeScale">[Github]</a> 

                        </div>
                    </div>
        </div>
    );
}

export default Project2;